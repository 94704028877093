import { Injectable } from '@angular/core';
import { AppService } from '../../services/app.service';
import { PermissionService } from '../../services/permission.service';
import { ProductsCategoryService } from '../../services/products-category.service';
import { Product } from '../../services/products.service';
import { QuoteDiscount, QuoteDiscountsService } from '../../services/quote-discounts.service';
import { BehaviorSubject } from 'rxjs';
import {
    ConditionType,
    OfferCondition,
    OfferCreationParams,
    OfferItem,
    OfferParams,
    OfferPriceCalculationParams,
    OfferPrintOptions,
    PriceLiteParams,
} from './price-summary.model';
import { Quote } from '../../services/quote.service';
import { EnvironmentDiffService } from '../../services/environment-diff.service';

export interface ProductWithGroup extends Product {
    group?: string;
}

@Injectable({
    providedIn: 'root',
})
export class PriceSummaryHelperService {
    public priceAdvantageEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(
        private permissionService: PermissionService,
        private quoteDiscountsService: QuoteDiscountsService,
        private appService: AppService,
        private productCategory: ProductsCategoryService,
        private environmentDiffService: EnvironmentDiffService
    ) {}

    public getPriceLiteParams(products: Product[]): PriceLiteParams {
        const priceAdvantageEnabled = this.priceAdvantageEnabled$.value;
        return {
            salesOrg: this.appService.getSalesOrg(),
            materials: products.map((p) => ({ materialNumber: p.material, amount: p.quantity })),
            discounts: priceAdvantageEnabled ? this.productCategory.getProductDiscountCategoriesFromCache() : [],
            priceAdvantageEnabled,
        };
    }

    public getOfferPriceCalculationParams(
        customerNumber: string,
        voucherCode: string,
        productList: ProductWithGroup[],
        quote: Quote
    ): OfferPriceCalculationParams {
        return {
            language: this.appService.getLanguageOnly(),
            offer: this.getOfferParams(customerNumber, voucherCode, productList, quote),
            printOptions: this.getPrintOptions(quote),
        };
    }

    public getOfferCreationParams(
        customerNumber: string,
        voucherCode: string,
        productList: ProductWithGroup[],
        quote: Quote
    ): OfferCreationParams {
        return {
            ...this.getOfferParams(customerNumber, voucherCode, productList, quote),
            printOptions: this.getPrintOptions(quote),
            texts: this.getOfferTexts(),
        };
    }

    private getOfferTexts(): { [key: string]: string } {
        const texts = Object.entries({
            '0010': this.environmentDiffService.getHybridPackageFromCache(),
        }).filter(([_, value]) => !!value);
        return Object.fromEntries(texts);
    }

    private getOfferParams(
        customerNumber: string,
        voucherCode: string,
        productList: ProductWithGroup[],
        quote: Quote
    ): OfferParams {
        const salesOrg = this.appService.getSalesOrg();
        const schemaId = quote.schemePdfs?.primary?.split(';')[0];

        const priceAdvantageEnabled = this.priceAdvantageEnabled$.value;
        let index = 1;
        const { categoryDiscounts, partnerDiscounts } = this.getDiscounts();
        const items: OfferItem[] = productList.map((product) => {
            const partnerDiscount = partnerDiscounts.find(
                (d) => d.materialGroup === product.materialGroup
            )?.partnerDiscount;
            const categoryDiscount =
                product.materialGroup !== 'N' &&
                categoryDiscounts.find((cd) => cd.category === product.category)?.discount;
            const conditions: OfferCondition[] = [
                categoryDiscount && { type: ConditionType.standard, value: -categoryDiscount },
                partnerDiscount && { type: ConditionType.partner, value: Number(partnerDiscount) },
            ].filter(Boolean);
            return {
                itemNumber: index++,
                material: product.material,
                quantity: product.quantity,
                group: product.group,
                optional: product.optional,
                conditions,
            };
        });
        const offerType = quote.noPriceAdvantage ? 'ZPPE' : quote.orderType;
        return {
            salesOrg,
            distrChan: '01',
            division: '01',
            customerPurchaseNumber: quote.quote.title,
            documentType: 'ZPAG',
            customerNumber,
            submissionNumber: quote.projectIdSF,
            salesforcePlanningId: quote.planningId,
            salesforceOpportunityId: quote.salesforceId,
            currency: quote.quote.currency,
            schemaId,
            voucherId: voucherCode,
            offerType,
            quoteAssistInfo: priceAdvantageEnabled ? 'P' : '',
            items,
        };
    }

    private getPrintOptions(quote: Quote): OfferPrintOptions {
        return {
            printType: this.appService.getPrintType(quote.printType),
            printLocale: this.appService.getLanguageAsLocale(),
            textMap: {
                COVER: {
                    name: 'COVER',
                    textItems: [
                        {
                            textModuleName: 'ZAN',
                            textContent: null,
                        },
                    ],
                },
            },
            options: {
                draft: false,
                printItemPrices: true,
                printDiscounts: true,
                printLogo: true,
            },
        };
    }

    public getDiscounts() {
        return {
            categoryDiscounts: this.priceAdvantageEnabled$.value
                ? this.productCategory.getProductDiscountCategoriesFromCache()
                : [],

            partnerDiscounts:
                this.permissionService.isUserAnEmployee && this.quoteDiscountsService.getQuoteDiscounts().length > 0
                    ? this.quoteDiscountsService.getQuoteDiscounts()
                    : [],
        };
    }

    public hasProductListChanged(array1: Array<any>, array2: Array<any>) {
        const arrayString1 = JSON.stringify(array1);
        const arrayString2 = JSON.stringify(array2);
        const result = arrayString1 !== arrayString2;
        return result;
    }

    public quoteDiscountsDiffer(prev: Array<QuoteDiscount>, current: Array<QuoteDiscount>): boolean {
        if (prev == null) {
            return true;
        }
        // 1. We convert each discount to a normalized string representation: GG_15
        // 2. we check the difference in both ways to determine if the arrays are equal
        const prevStrings = prev.map((it) => it.materialGroup + '_' + String(it.partnerDiscount));
        const currentStrings = current.map((it) => it.materialGroup + '_' + String(it.partnerDiscount));
        const diff1 = prevStrings.filter((it) => !currentStrings.includes(it));
        const diff2 = currentStrings.filter((it) => !prevStrings.includes(it));
        return diff1.length + diff2.length > 0;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AppService } from './app.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface Offer {
    items: {
        pos: number;
        material: string;
        quantity: number;
        shortText: string;
    }[];
}

@Injectable({
    providedIn: 'root',
})
export class OfferService {
    private offerRequests: Map<string, Observable<Offer>>;
    private offerCache: Map<string, Offer>;

    constructor(private http: HttpClient, private appService: AppService) {
        this.offerRequests = new Map<string, Observable<Offer>>();
        this.offerCache = new Map<string, Offer>();
    }

    public getOffer(offerNumber: string): Observable<Offer> {
        if (this.offerCache.has(offerNumber)) {
            return of(this.offerCache.get(offerNumber));
        }
        if (this.offerRequests.has(offerNumber)) {
            return this.offerRequests.get(offerNumber);
        }
        this.offerRequests.set(offerNumber, this.fetchOffer(offerNumber));
        return this.offerRequests.get(offerNumber);
    }

    private fetchOffer(offerNumber: string): Observable<Offer> {
        return this.http
            .get<Offer>(`${environment.http.quotation}/offers/${offerNumber}`, {
                params: {
                    language: this.appService.getLanguageOnly(),
                },
            })
            .pipe(
                tap((offer) => {
                    this.offerCache.set(offerNumber, offer);
                    this.offerRequests.delete(offerNumber);
                })
            );
    }
}

<div class="mat-dialog">
    <h2 mat-dialog-title class="mat-card-title">
        {{ 'NEXT_STEPS.EMAIL_DIALOG.TITLE.SEND_VIA_MAIL' | translate }}
    </h2>
    <div mat-dialog-content class="content">
        <div>
            <p class="text">
                {{ 'NEXT_STEPS.EMAIL_DIALOG.CONTENT.EXPORT_DOCUMENTS_INFO' | translate }}
            </p>
            <div class="attachments-overview">
                <ul>
                    <li>{{ 'NEXT_STEPS.LABEL.QUOTE_PDF' | translate }} PDF</li>
                    <li *ngFor="let format of data.selectedExportFormats">{{ format.replace('_', ' ') }}</li>
                    <li *ngIf="data.schemes?.length">{{ 'NEXT_STEPS.LABEL.SCHEME' | translate }}</li>
                    <li *ngIf="data.schemesDwt?.length">{{ 'NEXT_STEPS.LABEL.SCHEME_DWT' | translate }}</li>
                    <li *ngIf="data.energyLabelSelected">
                        {{ 'NEXT_STEPS.LABEL.ENERGY_LABEL' | translate }}
                    </li>
                </ul>
                <ul *ngIf="data.offerAttachments.length">
                    <li *ngFor="let attachment of data.offerAttachments">
                        {{ attachment.filename }}
                    </li>
                </ul>
                <ul *ngIf="data.additionalOfferAttachments.length">
                    <li *ngFor="let attachment of data.additionalOfferAttachments">
                        {{ attachment.name }}
                    </li>
                </ul>
            </div>
            <p class="text">
                {{ 'NEXT_STEPS.EMAIL_DIALOG.CONTENT.ENTER_EMAIL_INFO' | translate
                }}<vi-quest-hint
                    [model]="{ text: 'NEXT-STEPS.EMAIL_DIALOG.CONTENT.TOOLTIP' | translate, important: false }"
                >
                </vi-quest-hint>
            </p>
            <form [formGroup]="sendEmailForm">
                <div *ngIf="data.mailRecipients.length > 0 && isEmployee()">
                    <mat-form-field>
                        <mat-label>{{ 'COMMON.EMAIL_SELECT' | translate }}</mat-label>
                        <mat-select id="emailSelect" formControlName="emailSelect" multiple>
                            <mat-option *ngFor="let recipient of data.mailRecipients" [value]="recipient">{{
                                recipient
                            }}</mat-option>
                        </mat-select>
                        <mat-icon class="email-icon" matPrefix>email</mat-icon>
                        <mat-error>
                            {{ 'NEXT_STEPS.EMAIL_DIALOG.CONTENT.EMAIL_VALIDATION_ERROR_MSG' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <div class="form-field-divider">{{ 'COMMON.OR' | translate }}</div>
                </div>
                <mat-form-field>
                    <mat-label>{{ 'COMMON.EMAIL_INPUT' | translate }}</mat-label>
                    <input id="email" type="email" formControlName="email" class="email-input" matInput />
                    <mat-icon class="email-icon" matPrefix>email</mat-icon>
                    <mat-error>
                        {{ 'NEXT_STEPS.EMAIL_DIALOG.CONTENT.EMAIL_VALIDATION_ERROR_MSG' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'COMMON.EMAIL_CC' | translate }}</mat-label>
                    <input id="emailCC" type="email" formControlName="emailCC" class="email-input" matInput />
                    <mat-icon class="email-icon" matPrefix>email</mat-icon>
                    <mat-error>
                        {{ 'NEXT_STEPS.EMAIL_DIALOG.CONTENT.EMAIL_VALIDATION_ERROR_MSG' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'COMMON.EMAIL_BCC' | translate }}</mat-label>
                    <input id="emailBCC" type="email" formControlName="emailBCC" class="email-input" matInput />
                    <mat-icon class="email-icon" matPrefix>email</mat-icon>
                    <mat-error>
                        {{ 'NEXT_STEPS.EMAIL_DIALOG.CONTENT.EMAIL_VALIDATION_ERROR_MSG' | translate }}
                    </mat-error>
                </mat-form-field>
                <div
                    class="content-mailtext pre-filled"
                    *ngIf="!sendEmailForm.controls['emailBody'].value && !!data.mailText"
                >
                    <div class="col-2 p-0 pre-label">{{ 'COMMON.EMAIL_AUTOMATIC_MESSAGE' | translate }}</div>
                    <div class="col-10 text-container" [innerHTML]="sanitizedHtml" *ngIf="!!sanitizedHtml"></div>
                </div>
                <mat-form-field>
                    <mat-label>{{ 'NEXT-STEPS.EMAIL_DIALOG.CONTENT.EMAIL_BODY.PLACEHOLDER' | translate }}</mat-label>
                    <textarea matInput cdkTextareaAutosize formControlName="emailBody"></textarea>
                    <mat-icon class="email-icon" matPrefix>edit</mat-icon>
                </mat-form-field>
            </form>
        </div>
    </div>

    <div class="footer" mat-dialog-actions fxLayout="row">
        <div fxFlex *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="10px">
            <button mat-button color="primary" (click)="onNoClick()">
                {{ 'COMMON.DIALOG.CANCEL' | translate }}
            </button>
            <button
                mat-raised-button
                color="primary"
                [disabled]="isLoading || !sendEmailForm.valid"
                (click)="onYesClick()"
            >
                <mat-icon>send</mat-icon>
                {{ 'COMMON.DIALOG.SEND' | translate }}
            </button>
        </div>
        <div fxFlex *ngIf="isLoading" fxLayoutAlign="center center">
            <app-loader [message]="'NEXT_STEPS.EMAIL_DIALOG.LOADER.SENDING_DOCUMENTS' | translate"></app-loader>
        </div>
    </div>
</div>

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { PermissionService } from '../_shared/services/permission.service';
import { LineType, QuoteService } from '../_shared/services/quote.service';
import { SnackBarService } from '../_shared/services/snack-bar.service';
import { ValidateValueStateMatcher } from '../_shared/validation/ValidateValueStateMatcher';
import { SelectedCustomerService } from '../_shared/services/selected-customer.service';
import { takeUntil } from 'rxjs/operators';
import { AppService } from '../_shared/services/app.service';
import { PriceSummaryDataService } from '../_shared/components/price-summary/price-summary.data.service';
import { ProductListService } from '../_shared/components/product-list/product-list.service';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit, OnDestroy {
    @ViewChild('questionaire')
    public questionaireElement: ElementRef;

    @ViewChild('offerTitleInput')
    public offerTitleElement: ElementRef;

    @ViewChild('contentContainer')
    public wrapperDiv: ElementRef;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    private originalOfferTitle: string;

    public loaderTextKey = 'CONFIGURATION.LOADER.LOADING_WIZARD';
    public isOfferTitleDisabled = true;
    public offerTitle: string;
    public quickRef = '...';
    public latestRevision: string;
    public documentId: string;
    public salesforceId: string;
    public planning: boolean;
    public quotationRequestId: string;
    public lineType: LineType;

    public isQuestFormReady = false;
    public isQuestFormComplete = false;
    public errorOnEmptyMatcher = new ValidateValueStateMatcher((value) => value !== '');

    constructor(
        private appService: AppService,
        private activatedRoute: ActivatedRoute,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        private quoteService: QuoteService,
        private router: Router,
        public permissionService: PermissionService,
        private selectedCustomerService: SelectedCustomerService,
        private priceSummaryDataService: PriceSummaryDataService,
        private productListService: ProductListService
    ) {}

    public submit() {
        (<any>this.questionaireElement).submit();
    }

    public get hasErrors() {
        return this.priceSummaryDataService.hasErrors$;
    }

    public get isPriceSummaryLoading() {
        return this.priceSummaryDataService.isPriceSummaryLoading$;
    }

    public get isProductListLoading() {
        return this.productListService.isProductListLoading$;
    }

    public onQuestFormReady() {
        this.isQuestFormReady = true;
    }

    public onQuestFormComplete(isComplete: boolean) {
        this.isQuestFormComplete = isComplete;
        const selectedPartner = this.selectedCustomerService.customerForPartnerMode$.value;
        if (selectedPartner && !this.selectedCustomerService.selectedCustomer$.value) {
            // preset selected partner if partner mode was active
            this.selectedCustomerService.selectedCustomer$.next(selectedPartner);
        }
    }

    public ngOnInit() {
        this.appService.appTitle$.next('');
        this.assignPropertiesFromActivatedRoute();
        this.quoteService.quoteErrors$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.showSnackBar('CONFIGURATION.SNACK_BAR.SAVE_QUOTE.ERROR', true);
        });
    }

    public onOfferTitleClick() {
        this.isOfferTitleDisabled = false;
    }

    public onOfferTitleBlur() {
        this.originalOfferTitle = this.originalOfferTitle || '';
        this.offerTitle = this.offerTitle || '';

        this.isOfferTitleDisabled = true;

        if (this.originalOfferTitle !== this.offerTitle.trim()) {
            this.updateQuoteTitle(this.offerTitle);
        }
    }

    private assignPropertiesFromActivatedRoute(): void {
        const { title, quickRef, documentId, latestRevision, planning, salesforceId, quotationRequestId, lineType } =
            this.activatedRoute.snapshot.queryParams;

        this.documentId = documentId;
        this.latestRevision = latestRevision;
        this.quickRef = quickRef;
        this.originalOfferTitle = title;
        this.offerTitle = title;
        this.lineType = lineType;

        this.planning = Boolean(planning);
        if (salesforceId) {
            this.salesforceId = salesforceId;
        }
        if (quotationRequestId) {
            this.quotationRequestId = quotationRequestId;
        }
    }

    private updateQuoteTitle(newTittle: string) {
        this.quoteService.updateQuoteTitle(newTittle);
        this.originalOfferTitle = this.offerTitle;
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { title: newTittle },
            queryParamsHandling: 'merge',
        });
        this.showSnackBar('CONFIGURATION.SNACK_BAR.UPDATE_OFFER_TITLE.SUCCESS', false);
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private showSnackBar(translationKey: string, isFailure: boolean): void {
        this.snackBarService.openSnackBar({
            message: this.translateService.instant(translationKey),
            isFailure,
        });
    }
}

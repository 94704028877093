import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Observable, Subject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
    CalculatePriceLiteResponse,
    OfferPriceCalculationParams,
    OfferPriceCalculationResponse,
    PriceLiteParams,
    PriceSummary,
} from './price-summary.model';

@Injectable({
    providedIn: 'root',
})
export class PriceSummaryDataService {
    public isPriceSummaryLoading$: Subject<{ value: boolean }> = new Subject<{ value: boolean }>();

    public hasErrors$: Subject<{ value: boolean }> = new Subject<{ value: boolean }>();

    private lastPriceSummary: PriceSummary;

    constructor(private httpService: HttpService) {}

    public fetchPriceFull(payload: OfferPriceCalculationParams): Observable<PriceSummary> {
        this.onStart();
        const url = `${environment.http.quotation}price/calculate`;
        const result: Observable<PriceSummary> = this.httpService
            .post<OfferPriceCalculationResponse>(url, payload)
            .pipe(
                map(({ offer, prices }) => {
                    return {
                        isFull: true,
                        grossPrices: offer.items.map((item) => ({
                            material: item.material,
                            gross: item.grossPrice,
                        })),
                        prices,
                    };
                })
            );
        return this.onEnd(result);
    }

    public fetchPriceLite(params: PriceLiteParams): Observable<PriceSummary> {
        this.onStart();
        let result: Observable<PriceSummary>;

        const url = `${environment.http.quotation}price/calculate/lite`;
        result = this.httpService
            .post<CalculatePriceLiteResponse>(url, params)
            .pipe(map((res) => ({ ...res, isFull: false })));

        return this.onEnd(result);
    }

    private onStart() {
        this.isPriceSummaryLoading$.next({ value: true });
    }

    private onEnd(result: Observable<PriceSummary>) {
        return result.pipe(
            tap((result) => (this.lastPriceSummary = result)),
            finalize(() => {
                this.isPriceSummaryLoading$.next({ value: false });
            })
        );
    }

    public getLastPriceSummary() {
        return this.lastPriceSummary;
    }
}

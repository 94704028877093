import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { HttpService } from '../../services/http.service';
import { PermissionService } from '../../services/permission.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { UrlService } from '../../services/url.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AdditionalAttachment } from '../../../next-steps/next-steps.data.service';

const EMAIL_SEPERATOR = /[,;]/; // separate by "," or ";"

function commaSeparatedEmailsValidator(control: AbstractControl): ValidationErrors {
    const errors = (<string>control.value)
        .split(EMAIL_SEPERATOR)
        .map((email) => Validators.email(<AbstractControl>{ value: email.trim() }))
        .filter((v) => v !== null);
    if (errors.length) {
        return { email: true };
    }
    return null;
}

export const atLeastOneValidator = (keys: string[]) => {
    return (group: FormGroup) => {
        const { controls } = group;
        return keys.some((key) => {
            const value = controls[key] && controls[key].value;
            return value && (!Array.isArray(value) || value.length > 0);
        })
            ? null
            : { atLeastOne: 'error' };
    };
};

@Component({
    selector: 'app-send-documents-via-email-dialog',
    templateUrl: './send-documents-via-email-dialog.component.html',
    styleUrls: ['./send-documents-via-email-dialog.component.scss'],
})
export class SendDocumentsViaEmailDialogComponent implements OnInit {
    public sendEmailForm = new FormGroup<{
        emailSelect: FormControl<string>;
        email: FormControl<string>;
        emailCC: FormControl<string>;
        emailBCC: FormControl<string>;
        emailBody: FormControl<string>;
    }>(
        {
            emailSelect: new FormControl<string>('', []),
            email: new FormControl<string>('', [commaSeparatedEmailsValidator]),
            emailCC: new FormControl<string>('', [commaSeparatedEmailsValidator]),
            emailBCC: new FormControl<string>('', [commaSeparatedEmailsValidator]),
            emailBody: new FormControl<string>(''),
        },
        { validators: atLeastOneValidator(['emailSelect', 'email']) }
    );
    public isLoading = false;
    public sanitizedHtml: SafeHtml;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SendDocumentSettings,
        public dialogRef: MatDialogRef<SendDocumentsViaEmailDialogComponent>,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private urlService: UrlService,
        private appService: AppService,
        private httpService: HttpService,
        private sanitizer: DomSanitizer
    ) {}

    public ngOnInit() {
        if (this.permissionService.userInfo$.value && this.permissionService.userInfo$.value.mail) {
            const prefillControl = this.permissionService.isUserAnEmployee
                ? this.sendEmailForm.controls.emailCC
                : this.sendEmailForm.controls.email;
            prefillControl.setValue(this.permissionService.userInfo$.value.mail);
        }
        this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(this.data.mailText || '');
    }

    public onNoClick() {
        this.dialogRef.close();
    }

    public onYesClick() {
        this.isLoading = true;
        this.sendMail();
    }

    private setControlsEnabled(enabled: boolean) {
        Object.values(this.sendEmailForm.controls).forEach((control) => {
            if (enabled) {
                control.enable();
            } else {
                control.disable();
            }
        });
    }

    public isEmployee() {
        return this.permissionService.isUserAnEmployee;
    }

    private sendMail() {
        this.setControlsEnabled(false);
        const { emailSelect, email, emailCC, emailBCC, emailBody } = this.sendEmailForm.value as {
            emailSelect: string;
            email: string;
            emailCC: string;
            emailBCC: string;
            emailBody: string;
        };
        const toEmailList = (mail: string) =>
            mail
                .split(EMAIL_SEPERATOR)
                .map((v) => v.trim())
                .filter((v) => !!v);

        const mails = toEmailList(email);

        const mergeEmailLists = (originalList, additionalList) =>
            [...new Set([...originalList, ...additionalList])].filter((v) => !!v);

        this.sendMailWithDocuments({
            documentId: this.data.documentId,
            latestRevision: this.data.latestRevision,
            schemes: this.data.schemes,
            schemesDwt: this.data.schemesDwt,
            energyLabel: this.data.energyLabel,
            exportFormats: this.data.selectedExportFormats,
            emails: mergeEmailLists(mails, emailSelect),
            emailsCC: toEmailList(emailCC),
            emailsBCC: toEmailList(emailBCC),
            emailBody,
            offerAttachments: this.data.offerAttachments,
            additionalOfferAttachments: this.data.additionalOfferAttachments.map((a) => a.id),
            printType: this.data.printType,
        }).subscribe(
            () => {
                this.snackBarService.openSnackBar({
                    message: this.translateService.instant('NEXT_STEPS.EMAIL_DIALOG.SENDING_DOCUMENTS.SUCCESS_MSG'),
                    isFailure: false,
                });
                this.dialogRef.close();
                this.isLoading = false;
                this.setControlsEnabled(true);
            },
            () => {
                this.snackBarService.openSnackBar({
                    message: this.translateService.instant('NEXT_STEPS.EMAIL_DIALOG.SENDING_DOCUMENTS.FAILURE_MSG'),
                    isFailure: true,
                });
                this.isLoading = false;
                this.setControlsEnabled(true);
            }
        );
    }

    public sendMailWithDocuments({
        documentId,
        latestRevision,
        schemes,
        schemesDwt,
        energyLabel,
        exportFormats,
        emails,
        emailsCC,
        emailsBCC,
        emailBody,
        offerAttachments,
        additionalOfferAttachments,
        printType,
    }: {
        documentId: string;
        latestRevision: string;
        schemes: string[];
        schemesDwt: string[];
        energyLabel: string;
        exportFormats: Array<string>;
        emails: Array<string>;
        emailsCC: Array<string>;
        emailsBCC: string[];
        emailBody: string;
        offerAttachments: any[];
        additionalOfferAttachments: string[];
        printType: string;
    }) {
        const url = this.urlService.getUrl('sendMailWithDocuments', {
            documentId,
            latestRevision,
        });

        const payload = {
            emailReceivers: emails,
            emailCCReceivers: emailsCC,
            emailBCCReceivers: emailsBCC,
            emailBody,
            sendEmailToSalesRep: false,
            exportFormats,
            language: this.appService.getLanguageOnly(),
            country: this.appService.getLocation(),
            salesOrganization: this.appService.getSalesOrg(),
            schemes,
            schemesDwt,
            ...(this.data.energyLabelSelected && { energyLabel }),
            offerAttachments,
            additionalOfferAttachments,
            printType,
        };

        return this.httpService.post(url, payload);
    }
}

export interface SendDocumentSettings {
    selectedExportFormats: string[];
    energyLabelSelected: boolean;
    energyLabel: string;
    schemes: string[];
    schemesDwt: string[];
    documentId: string;
    latestRevision: string;
    offerAttachments: any[];
    additionalOfferAttachments: AdditionalAttachment[];
    printType: string;
    mailText: string;
    mailRecipients: string[];
}

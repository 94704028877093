import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigitSettings } from '../components/questionnaire/configit-quest-adapter/configit-types.interface';
import { PrintType } from './quote.service';

export interface ProductTranslations {
    [key: string]: string;
}

export interface Locale {
    locale: string;
    flag: string;
    salesOrg: string;
}

@Injectable({
    providedIn: 'root',
})
export class AppService {
    public readonly localStorageKeyLanguage = 'cultureLang';
    public readonly localStorageKeySalesorg = 'salesOrg';

    constructor(private translate: TranslateService) {}

    public configuration$: BehaviorSubject<ConfigitSettings> = new BehaviorSubject<ConfigitSettings>(undefined);

    public onLanguageChanged$: BehaviorSubject<string> = new BehaviorSubject<string>(environment.app.defaultLanguage);

    public availableSalesOrgs$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    public availableLanguages$: BehaviorSubject<Locale[]> = new BehaviorSubject<Locale[]>([]);

    public salesOrg$: BehaviorSubject<string> = new BehaviorSubject<string>(environment.configit.plant);

    public appTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public getSalesAreaId() {
        const salesArea = this.getSalesArea();
        if (!salesArea) {
            return '';
        }
        const { salesOrganization, distributionChannel, salesDivision } = salesArea;
        return `${salesOrganization}/${distributionChannel}/${salesDivision}`;
    }

    public getSalesOrg(): string {
        return this.salesOrg$.value;
    }

    public isNoPriceAdvantageCheckboxVisible() {
        const salesArea = this.getSalesArea();
        return salesArea?.noPriceAdvantage?.enabled;
    }

    private getSalesArea(salesOrg = this.getSalesOrg()) {
        const config = this.configuration$.value;
        if (config) {
            return config.allowedSalesAreas.find((salesArea) => salesArea.salesOrganization === salesOrg);
        }
        return null;
    }

    public setSalesOrg(salesOrg: string, persistent: boolean) {
        if (salesOrg && this.salesOrg$.value !== salesOrg) {
            if (persistent) {
                localStorage.setItem(this.localStorageKeySalesorg, salesOrg);
            }
            this.salesOrg$.next(salesOrg);
        }
    }

    public getSalesAreaName(): string {
        return this.getSalesArea()?.name;
    }

    public isSalesOrgSelectable(salesOrg: string): boolean {
        return this.getSalesArea(salesOrg)?.selectable || false;
    }

    public extractLanguage(languageAndCountry: string) {
        return languageAndCountry && languageAndCountry.includes('-')
            ? languageAndCountry.split('-')[0]
            : languageAndCountry;
    }

    public getLocalStorageSalesOrg(): string {
        return localStorage.getItem(this.localStorageKeySalesorg);
    }

    public getLocalStorageLanguage(): string {
        return localStorage.getItem(this.localStorageKeyLanguage);
    }

    public getLanguage(): string {
        return this.onLanguageChanged$.value;
    }

    public getLanguageAsLocale() {
        return this.getLanguage().replace('-', '_');
    }

    public setLanguage(languageAndCountry: string, persistent: boolean) {
        if (languageAndCountry) {
            this.translate.use(languageAndCountry);
            this.onLanguageChanged$.next(languageAndCountry);
            if (persistent) {
                localStorage.setItem(this.localStorageKeyLanguage, languageAndCountry);
            }
        }
    }

    public getLanguageOnly() {
        return this.extractLanguage(this.getLanguage());
    }

    public getLocation() {
        const location = this.getLanguage().split('-')[1] || this.getLanguageOnly().toUpperCase();
        if (location === 'EN') {
            return 'GB';
        } else {
            return location;
        }
    }

    public getPrintType(printType: PrintType) {
        switch (printType) {
            case 'S':
                return 'SHORT';
            case 'M':
                return 'MIDDLE';
            case 'L':
                return 'LONG';
        }
    }

    public getBrowserOrQueryLanguage() {
        const languageFromQuery = new URLSearchParams(window.location.search).get('lang');
        const browserLanguage = this.translate.getBrowserCultureLang();
        const language = languageFromQuery || browserLanguage;
        const locale = this.getSupportedLanguage(language) || this.getSupportedLanguage(this.extractLanguage(language));
        return locale?.locale || environment.app.defaultLanguage;
    }

    private getSupportedLanguage(lang: string) {
        return environment.app.supportedLanguages.find((sl) => sl.locale.startsWith(lang));
    }

    public chooseTranslation(productTranslations?: ProductTranslations) {
        if (productTranslations === undefined) {
            return '';
        }

        return (
            productTranslations[this.getLanguage()] ||
            productTranslations[this.getLanguageOnly()] ||
            productTranslations[this.getBrowserOrQueryLanguage()] ||
            ''
        );
    }

    public get isAuthorizedToUseTemplates() {
        const salesArea = this.getSalesArea();
        return salesArea?.templates?.enabled;
    }

    public get isOptionalPositionsEnabled() {
        const salesArea = this.getSalesArea();
        return salesArea?.optionalPositions?.enabled;
    }
}

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { OverviewDataSource } from '../../../overview/overview-data-source';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { openQuoteCreationDialog } from '../quote-creation-dialog/quote-creation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { OverlayDialogHelper } from '../../utils/overlay-dialog.helper';
import { ImageDialogComponent, ImageDialogData } from './image-dialog/image-dialog.component';
import { DOCUMENT } from '@angular/common';
import { PermissionService } from '../../services/permission.service';

@Component({
    selector: 'app-templates-table',
    templateUrl: './templates-table.component.html',
    styleUrls: ['./templates-table.component.scss'],
})
export class TemplatesTableComponent implements OnInit, AfterViewInit, OnDestroy {
    private helper: OverlayDialogHelper<ImageDialogComponent, ImageDialogData>;

    public columnsToDisplayForCustomer = ['title', 'schemePreview', 'description', 'actions'];
    public columnsToDisplayForEmployee = ['title', 'schemePreview', 'description', 'createdDate', 'actions'];
    public columnsToDisplay;

    @ViewChild(MatSort, { static: true })
    public sort: MatSort;

    @ViewChild(MatPaginator, { static: true })
    public paginator: MatPaginator;

    @Input()
    public dataSource: OverviewDataSource<any, any>;

    @Output()
    public useTemplate = new EventEmitter<any>();

    @Output()
    public pagingError = new EventEmitter<any>();
    @Output()
    public sortError = new EventEmitter<any>();

    constructor(
        @Inject(DOCUMENT) document: Document,
        private translationService: TranslateService,
        private dialog: MatDialog,
        private permissionService: PermissionService
    ) {
        this.helper = new OverlayDialogHelper<ImageDialogComponent, ImageDialogData>(
            document,
            dialog,
            ImageDialogComponent,
            'image-dialog'
        );
    }

    ngOnInit(): void {
        this.dataSource.init(this.paginator, this.sort);
        this.translatePaginator();
        this.setColumnsToDisplay();
    }

    private setColumnsToDisplay() {
        this.columnsToDisplay = this.permissionService.isUserAnEmployee
            ? this.columnsToDisplayForEmployee
            : this.columnsToDisplayForCustomer;
    }

    onSelectTemplate(quote: { title: string; description: string }) {
        openQuoteCreationDialog(this.dialog, {
            title: this.translationService.instant('OVERVIEW.DIALOG.USE_TEMPLATE.TEMPLATE_TITLE', {
                title: quote.title,
            }),
            confirm: this.translationService.instant('OVERVIEW.DIALOG.USE_TEMPLATE.CONFIRM'),
            cancel: this.translationService.instant('COMMON.DIALOG.CANCEL'),
            description: this.translationService.instant('OVERVIEW.DIALOG.USE_TEMPLATE.DESCRIPTION'),
            templateDescription: this.translationService.instant('OVERVIEW.DIALOG.USE_TEMPLATE.TEMPLATE_DESCRIPTION', {
                description: quote.description,
            }),
        }).subscribe((title) => {
            if (title) {
                this.useTemplate.emit({
                    ...quote,
                    title,
                });
            }
        });
    }

    public onImageHover(event: MouseEvent, obj) {
        this.helper.handleEvent(event, {
            quoteId: obj.documentId,
            imageUrl: obj.schemePreview,
        });
    }

    private translatePaginator() {
        const intl = this.paginator._intl;
        intl.itemsPerPageLabel = this.translationService.instant('OVERVIEW.TABLE.PAGINATOR.LABEL.ITEMS_PER_PAGE');
        intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
            const start = page * pageSize;
            const end = start < length ? Math.min(start + pageSize, length) : start + pageSize;
            return this.translationService.instant('OVERVIEW.TABLE.PAGINATOR.LABEL.RANGE', {
                page: start + 1,
                pageSize: end,
                length,
            });
        };
        intl.changes.next();
    }

    public ngAfterViewInit(): void {
        // listen to paging events
        this.paginator.page
            .pipe(
                mergeMap(() =>
                    this.dataSource.loadOverviewData({ sortBy: 'description', sortOrder: 'asc', pageSize: 50 })
                ),
                catchError(() => {
                    this.pagingError.emit();
                    return EMPTY;
                })
            )
            .subscribe();

        // listen to sort events
        this.sort.sortChange
            .pipe(
                tap(() => this.dataSource.resetPaging()), // reset to first page when sorting
                mergeMap(() =>
                    this.dataSource.loadOverviewData({ sortBy: 'description', sortOrder: 'asc', pageSize: 50 })
                ),
                catchError(() => {
                    this.sortError.emit();
                    return EMPTY;
                })
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.helper.destroy();
    }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import {
    catchError,
    concatMap,
    debounceTime,
    delay,
    distinctUntilChanged,
    filter,
    first,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppService } from '../../services/app.service';
import { FeatureService } from '../../services/feature.service';
import { PermissionService } from '../../services/permission.service';
import { ProductsService } from '../../services/products.service';
import { QuoteDiscount, QuoteDiscountsService } from '../../services/quote-discounts.service';
import { SelectedCustomerService } from '../../services/selected-customer.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { ProductListService } from '../product-list/product-list.service';
import { PriceSummaryDataService } from './price-summary.data.service';
import { PriceSummaryHelperService, ProductWithGroup } from './price-summary.helper.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UrlService } from '../../services/url.service';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { Discount, QuoteDiscountDialogComponent } from '../quote-discount-dialog/quote-discount-dialog.component';
import { HttpService } from '../../services/http.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { QuoteService } from '../../services/quote.service';
import { ProductsCategoryService } from '../../services/products-category.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { VoucherService, VoucherType } from '../../services/voucher.service';
import { PriceSummary, PriceSummaryValidationError } from './price-summary.model';

@Component({
    selector: 'app-price-summary',
    templateUrl: './price-summary.component.html',
    styleUrls: ['./price-summary.component.scss'],
})
export class PriceSummaryComponent implements OnInit, OnDestroy {
    constructor(
        private dialog: MatDialog,
        private http: HttpService,
        private helperService: PriceSummaryHelperService,
        private productsService: ProductsService,
        private productsCategoryService: ProductsCategoryService,
        private productListService: ProductListService,
        public appService: AppService,
        private urlService: UrlService,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        public dataService: PriceSummaryDataService,
        public quoteDiscountsService: QuoteDiscountsService,
        public selectedCustomerService: SelectedCustomerService,
        public features: FeatureService,
        private permissionService: PermissionService,
        private quoteService: QuoteService,
        private voucherService: VoucherService,
        fb: UntypedFormBuilder
    ) {
        this.group = fb.group({
            voucherCode: [
                '',
                () => null,
                this.debouncedValueValidatorFactory(1000, this.asyncValidator.bind(this)).bind(this),
            ],
        });

        this.group
            .get('voucherCode')
            .statusChanges.pipe(
                filter((status) => status !== 'PENDING'),
                map((state) => [this.group.get('voucherCode').value, state]),
                map(([value, state]) => (state === 'VALID' ? value : '')),
                map((value: string) => value.toUpperCase()),
                distinctUntilChanged()
            )
            .subscribe((validValue) => {
                this.voucherCodeChanged.emit(validValue);
                this.currentVoucher = validValue;
                this.voucherCode$.next(validValue);
            });
    }

    private unsubscribe$: Subject<void> = new Subject<void>();
    private previousProductsValue: Array<any>;
    private previousQuoteDiscounts: Array<QuoteDiscount>;
    private previousDisplayPriceAdvantage: boolean;
    private discounts$: BehaviorSubject<Discount[]> = new BehaviorSubject<Discount[]>([]);
    private priceSummarySubscription: Subscription;

    public get displayPriceAdvantage$(): Subject<boolean> {
        return this.helperService.priceAdvantageEnabled$;
    }

    public isLoading = false;
    public totalGross = 0;
    public discount = 0;
    public totalPrice = 0;
    public netPrice = 0;
    public vat = 0;
    public sum = 0;
    public voucherValue = 0;
    public extraBonus = 0;
    public currency: string;

    @Input()
    public isSummaryPage = false;

    @Input()
    public dataSource: any;

    @Output()
    public voucherCodeChanged = new EventEmitter<string>();

    public showPartnerSelectionHint = false;

    public group: UntypedFormGroup;

    public errorMessage: string;

    public validationInProgress = false;

    private voucherSet: boolean;

    private quoteDiscountDialog: MatDialogRef<QuoteDiscountDialogComponent>;

    // Voucher code
    private voucherType$: BehaviorSubject<VoucherType> = new BehaviorSubject<VoucherType>(null);
    public voucherCode$: BehaviorSubject<string> = new BehaviorSubject(null);

    public lastCustomer: any;

    public currentVoucher: string;
    public lastVoucherCode: string;
    public dirtyMatcher: ErrorStateMatcher = {
        isErrorState: (control: UntypedFormControl | null): boolean => !!(control && control.invalid && control.dirty),
    };

    public noPriceAdvantageCheckboxAvailable: boolean;

    public ngOnInit() {
        this.subscribeToProductList$();
        this.subscribePriceAdvantage();

        if (this.permissionService.isUserAnEmployee) {
            this.selectedCustomerService.selectedCustomer$
                .pipe(
                    takeUntil(this.unsubscribe$),
                    tap((value) => (this.showPartnerSelectionHint = !value || (!value.name && !value.number))),
                    filter((value) => value?.number && value?.name && this.isSummaryPage),
                    concatMap((value) => this.fetchDiscounts(value.number))
                )
                .subscribe(this.discounts$);
        }

        const quote = this.quoteService.getCurrentQuote();
        this.currency = quote.quote.currency;
        this.noPriceAdvantageCheckboxAvailable =
            typeof quote.noPriceAdvantage === 'boolean' &&
            this.permissionService.isUserAnEmployee &&
            this.appService.isNoPriceAdvantageCheckboxVisible() &&
            this.hasConfiguration;
    }

    public get partnerShopTranslationAvailable() {
        const translation = this.translateService.instant('PRICE_SUMMARY.INFO_TEXT.PARTNER_SHOP');
        return translation !== '' && translation !== 'PRICE_SUMMARY.INFO_TEXT.PARTNER_SHOP';
    }

    public get isKamVoucher() {
        return this.voucherType$.value === VoucherType.kam;
    }

    public get voucherTypeKey() {
        return this.isKamVoucher ? VoucherType.kam : 'DEFAULT';
    }

    public get isCustomer() {
        return !this.permissionService.isUserAnEmployee;
    }

    public showDiscountButton() {
        return this.permissionService.isUserAnEmployee && !this.selectedCustomerService.isPlannerOrArchitect$.value;
    }

    public asFormControl(object: unknown): UntypedFormControl {
        return <UntypedFormControl>object;
    }
    public isVoucherSet(): boolean {
        return this.voucherSet;
    }

    public get quoteNoPriceAdvantage() {
        return this.quoteService.getCurrentQuote().noPriceAdvantage;
    }

    public setNoPriceAdvantage(event: MatCheckboxChange) {
        this.quoteService.updateQuoteProperty('noPriceAdvantage', event.checked);
        this.group.get('voucherCode').updateValueAndValidity();
    }

    public openPartnerDiscountDialog() {
        this.discounts$.pipe(first()).subscribe(
            (discounts) => {
                if (!this.quoteDiscountDialog) {
                    this.quoteDiscountDialog = this.dialog.open(QuoteDiscountDialogComponent, {
                        data: {
                            showPriceAdvantage: this.helperService.priceAdvantageEnabled$.value,
                            discounts: this.filterDiscountsForQuoteDiscountDialog(discounts),
                        },
                    });
                    this.quoteDiscountDialog.afterClosed().subscribe(() => (this.quoteDiscountDialog = null));
                }
            },
            () => {
                this.snackBarService.openSnackBar({
                    message: this.translateService.instant('QUOTE_DISCOUNT_DIALOG.ERROR.DISCOUNTS_UNAVAILABLE'),
                    isFailure: true,
                });
            }
        );
    }

    public get hasConfiguration() {
        return this.quoteService.hasConfiguration();
    }

    private fixDiscounts(discounts: Discount[]) {
        discounts.forEach((d) => {
            if (!d.maxDiscount) {
                d.maxDiscount = 70;
            }
        });

        const salesOrg = this.appService.getSalesOrg();
        if (['0500', '5000', '6000'].includes(salesOrg)) {
            let discountFix: Map<string, number>;
            if (salesOrg === '6000') {
                discountFix = new Map([
                    ['E', 35.0],
                    ['F', 45],
                    ['P', 50],
                    ['PG', 45],
                    ['TA', 30],
                    ['TB', 18],
                    ['TF', 18],
                    ['V', 45],
                    ['VB', 45],
                    ['VC', 45],
                    ['VD', 45],
                    ['VE', 45],
                    ['VF', 45],
                    ['VG', 45],
                    ['VH', 45],
                    ['VJ', 45],
                    ['W', 45],
                    ['W1', 62],
                    ['W2', 40],
                    ['W3', 50],
                    ['W7', 50],
                    ['W8', 45],
                    ['W9', 40],
                    ['WA', 42],
                    ['WC', 51.7],
                    ['WD', 45],
                    ['WE', 45],
                    ['WF', 45],
                    ['WG', 45],
                    ['WH', 45],
                    ['WI', 45],
                    ['WJ', 50],
                    ['WK', 50],
                    ['WL', 45],
                    ['WM', 45],
                    ['WN', 45],
                    ['WO', 45],
                    ['WP', 45],
                    ['WQ', 45],
                    ['WR', 45],
                    ['WS', 42],
                    ['WT', 45],
                    ['WU', 45],
                    ['WV', 40],
                    ['WW', 40],
                    ['WX', 42],
                    ['WY', 42],
                    ['WZ', 50],
                    ['XB', 54.4],
                    ['XC', 55],
                    ['XD', 45],
                    ['XE', 30],
                    ['Y', 30],
                    ['YA', 30],
                    ['YB', 25],
                    ['YC', 30],
                    ['YD', 37],
                    ['YE', 30],
                    ['YF', 30],
                ]);
            } else {
                discountFix = new Map([
                    ['GA', 46.5],
                    ['GB', 46.5],
                    ['GC', 46],
                    ['GD', 26],
                    ['GG', 49],
                    ['GH', 53],
                    ['GE', 50],
                    ['GF', 25],
                    ['GI', 44],
                    ['GJ', 30],
                    ['GL', 50],
                ]);
            }

            discounts.forEach((d) => {
                if (discountFix.has(d.materialGroup)) {
                    d.maxDiscount = discountFix.get(d.materialGroup);
                }
            });
        }

        return discounts;
    }

    private fetchDiscounts(customerNumber: string): Observable<Discount[]> {
        const url = this.urlService.getUrl('customerDiscounts', {
            customer: customerNumber,
        });
        return this.http.get(url);
    }

    private filterDiscountsForQuoteDiscountDialog(discounts): Discount[] {
        // PP-414 filter out discounts which are not used in material list
        const materialsInProductList = Array.from(
            new Set(this.dataSource.data.map((it) => it.materialGroup).filter(Boolean))
        );
        const filteredDiscounts = discounts.filter((it) => materialsInProductList.includes(it.materialGroup));

        // PP-414 add material groups with 0 % partner discount when they exist in the product list
        //  - Find all materialsGroups available in the product list but are not included in the customers discounts
        const filteredDiscountMaterials = filteredDiscounts.map((it) => it.materialGroup);
        const materialsToBeAdded = materialsInProductList.filter(
            (it: string) => ![...filteredDiscountMaterials, 'N', 'C2'].includes(it)
        );
        const discountsToBeAdded = materialsToBeAdded.map((it) => ({
            materialGroup: it,
            discount: 0,
            maxDiscount: 0, // will be changed by fixDiscounts
        }));

        const useTheseDiscounts = filteredDiscounts.concat(discountsToBeAdded);

        return this.fixDiscounts(useTheseDiscounts).filter((it) => it.maxDiscount > 0);
    }

    private asyncValidator(value: string) {
        this.voucherSet = false;
        this.voucherType$.next(null);
        this.quoteService.updateQuoteProperty('voucherType', '');
        if (value === undefined || value === '') {
            return of(null);
        }
        this.validationInProgress = true;
        // if currently logged in user is a customer, just use the viCompanyId of the currently logged in user
        const isCustomerLoggedIn = this.permissionService.userInfo$.value?.role === 'customer';
        return this.selectedCustomerService.selectedCustomer$.pipe(
            first(),
            switchMap((customer) => {
                const customerNumber = isCustomerLoggedIn
                    ? this.permissionService.userInfo$.value.viCompanyId
                    : customer?.number;

                const materialNumbers = this.dataSource.filteredData
                    .filter((data) => data.material)
                    .map((data) => data.material);
                return forkJoin([
                    this.voucherService.getVoucherDetails(value).pipe(catchError(() => of(undefined))),
                    this.voucherService.validateVoucher(
                        value,
                        customerNumber,
                        materialNumbers,
                        this.quoteNoPriceAdvantage ? 'default' : 'QuoteAssist'
                    ),
                ]);
            }),
            map(([voucherDetails, validateResponse]) => {
                this.validationInProgress = false;
                if (
                    [VoucherType.discount, VoucherType.merchandising, VoucherType.kam].includes(voucherDetails?.type) &&
                    !isCustomerLoggedIn &&
                    !this.quoteNoPriceAdvantage
                ) {
                    return { noPriceAdvantageCheckboxError: true };
                }
                this.voucherType$.next(voucherDetails?.type); // this will implicitly hide the price advantage in case of voucher type 3
                this.quoteService.updateQuoteProperty('voucherType', voucherDetails?.type || '');

                if (validateResponse.valid) {
                    this.correctVoucherIsSet();
                    return null;
                } else {
                    this.errorMessage = validateResponse.messages[0];
                    return { validationError: true };
                }
            }),
            catchError(() => {
                this.validationInProgress = false;
                return of({ serverError: true });
            })
        );
    }

    private debouncedValueValidatorFactory(miliseconds: number, validator: (inputValue) => Observable<any>) {
        const debouncedSubject = new BehaviorSubject('');
        const debouncedObservable = debouncedSubject.pipe(debounceTime(miliseconds), distinctUntilChanged());
        return (control: UntypedFormControl) => {
            debouncedSubject.next(control.value);
            return debouncedObservable.pipe(first(), switchMap(validator));
        };
    }

    private correctVoucherIsSet() {
        this.voucherSet = true;
        if (this.quoteDiscountDialog && this.quoteDiscountDialog.getState() === MatDialogState.OPEN) {
            this.quoteDiscountDialog.close();
        }

        if (this.permissionService.isUserAnEmployee) {
            this.discounts$.pipe(first()).subscribe((discounts) => {
                const discountsToBeRemoved = this.filterDiscountsForQuoteDiscountDialog(discounts);
                const updatedDiscounts = this.quoteDiscountsService
                    .getQuoteDiscounts()
                    .filter((qd) => !discountsToBeRemoved.some((d) => d.materialGroup === qd.materialGroup));
                this.quoteDiscountsService.setQuoteDiscounts(updatedDiscounts);
            });
        }
    }

    private subscribePriceAdvantage() {
        combineLatest([
            this.appService.salesOrg$,
            this.selectedCustomerService.selectedCustomer$,
            this.quoteService.getCurrentQuoteObservable(),
            this.voucherType$.pipe(distinctUntilChanged()),
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([salesOrg, _, quote, voucherType]) => {
                if (
                    environment.app.priceAdvantageNotRelevantSalesOrgs.includes(salesOrg) ||
                    this.selectedCustomerService.isPlannerOrArchitect$.value ||
                    !this.hasConfiguration ||
                    quote.noPriceAdvantage ||
                    voucherType === VoucherType.kam
                ) {
                    return this.helperService.priceAdvantageEnabled$.next(false);
                } else {
                    return this.helperService.priceAdvantageEnabled$.next(true);
                }
            });
    }

    private calculatePrice(
        products: ProductWithGroup[],
        customerId: string,
        voucherCode: string
    ): Observable<PriceSummary> {
        if (this.isSummaryPage) {
            const params = this.helperService.getOfferPriceCalculationParams(
                customerId,
                voucherCode,
                products,
                this.quoteService.getCurrentQuote()
            );
            return this.dataService.fetchPriceFull(params);
        } else {
            const params = this.helperService.getPriceLiteParams(products);
            return this.dataService.fetchPriceLite(params);
        }
    }

    private fetchPriceSummary(
        products: Array<any>,
        quoteDiscounts: Array<QuoteDiscount>,
        customerId: string,
        voucherCode: string
    ): void {
        if (this.showPartnerSelectionHint) {
            return;
        }
        this.previousProductsValue = [...products];
        this.previousQuoteDiscounts = quoteDiscounts;
        this.previousDisplayPriceAdvantage = this.helperService.priceAdvantageEnabled$.value;

        this.dataService.hasErrors$.next({ value: false });

        if (this.priceSummarySubscription) {
            this.priceSummarySubscription.unsubscribe();
        }
        this.priceSummarySubscription = this.calculatePrice(products, customerId, voucherCode).subscribe({
            next: (res: PriceSummary) => {
                const totalAdvantage = -Math.abs(res.prices.totalAdvantage);
                this.totalGross = res.prices.totalGross;
                this.discount = totalAdvantage;
                this.totalPrice = res.prices.totalPrice;
                if (res.isFull) {
                    this.netPrice = res.prices.netPrice;
                    this.vat = res.prices.vat;
                    this.sum = res.prices.sum;
                    this.voucherValue = Math.abs(res.prices.voucherValue);
                    this.extraBonus = Math.abs(res.prices.extraBonus);
                }

                // set error if prices are all 0
                const hasErrors = !(this.totalGross || this.totalPrice || this.netPrice || this.sum);
                this.dataService.hasErrors$.next({ value: hasErrors });

                this.productsService.addPriceSummaryToCache(
                    res.prices.totalGross,
                    totalAdvantage,
                    res.prices.totalPrice,
                    res.isFull ? res.prices.netPrice : 0,
                    res.isFull ? res.prices.vat : 0,
                    res.isFull ? res.prices.sum : 0,
                    this.voucherValue
                );
            },
            error: (error) => {
                this.dataService.hasErrors$.next({ value: true });
                if (error.status === 422 && error.error?.validationErrors) {
                    const { validationErrors } = <PriceSummaryValidationError>error.error;
                    const missingMaterials = validationErrors
                        .map((ve) => ve.context)
                        .filter(({ key }) => key === 'product')
                        .map(({ value }) => value)
                        .filter(Boolean);
                    if (this.permissionService.isUserAnEmployee) {
                        // tslint:disable-next-line:no-console
                        console.log(`Price calculation: The following materials are missing: ${missingMaterials}`);
                    }
                }
                this.snackBarService.openSnackBar({
                    message: this.translateService.instant('CONFIGURATION.SNACK_BAR.FETCH_PRICES.ERROR'),
                    isFailure: true,
                });
            },
            complete: () => {
                this.priceSummarySubscription.unsubscribe();
                this.priceSummarySubscription = null;
            },
        });
    }
    private selectedCustomerChanged(customer: any) {
        if (this.lastCustomer?.number === customer?.number) {
            return false;
        }
        this.lastCustomer = customer;
        return true;
    }
    private voucherChanged(value: string) {
        if (this.lastVoucherCode === value) {
            return false;
        }
        this.lastVoucherCode = value;
        return true;
    }

    private subscribeToProductList$(): void {
        const productList$: Observable<any> = this.productListService.productList$.pipe(
            takeUntil(this.unsubscribe$),
            filter(Boolean),
            debounceTime(1000)
        );

        const quoteDiscountList$: Observable<any> = this.quoteDiscountsService.quoteDiscounts().pipe(
            map((it) => it.filter((discount) => !!Number(discount.partnerDiscount))),
            filter(Boolean)
        );

        const selectedCustomer$ = this.selectedCustomerService.selectedCustomer$;

        combineLatest([
            productList$,
            quoteDiscountList$,
            selectedCustomer$,
            this.voucherCode$,
            this.productsCategoryService.haveDiscountCategoriesChanged,
            this.helperService.priceAdvantageEnabled$.pipe(distinctUntilChanged()),
        ])
            .pipe(takeUntil(this.unsubscribe$), delay(10))
            .subscribe(([products, quoteDiscounts, selectedCustomer, voucherCode, _, displayPriceAdvantage]) => {
                const hasProductListChanged = this.helperService.hasProductListChanged(
                    this.previousProductsValue,
                    products
                );
                const quoteDiscountsDiffer = this.helperService.quoteDiscountsDiffer(
                    this.previousQuoteDiscounts,
                    quoteDiscounts
                );
                const selectedCustomerChanged = this.selectedCustomerChanged(selectedCustomer);
                const voucherChanged = this.voucherChanged(voucherCode);
                const isCustomerLoggedIn = this.permissionService.userInfo$.value?.role === 'customer';
                if (products.length === 0) {
                    this.totalGross = 0;
                    this.discount = 0;
                    this.totalPrice = 0;
                    this.netPrice = 0;
                    this.vat = 0;
                    this.sum = 0;
                    this.voucherValue = 0;
                    this.extraBonus = 0;

                    this.productsService.addPriceSummaryToCache(0, 0, 0, 0, 0, 0, 0);
                } else {
                    if (
                        (isCustomerLoggedIn || selectedCustomer?.number) &&
                        // fetch prices if products have changed or discounts have changed
                        // This is not great.
                        (hasProductListChanged ||
                            this.productsCategoryService.haveDiscountCategoriesChanged.value ||
                            quoteDiscountsDiffer ||
                            selectedCustomerChanged ||
                            voucherChanged ||
                            this.previousDisplayPriceAdvantage !== displayPriceAdvantage)
                    ) {
                        this.productsCategoryService.haveDiscountCategoriesChanged.next(false);

                        const customerId = isCustomerLoggedIn
                            ? this.permissionService.userInfo$.value.viCompanyId
                            : selectedCustomer?.number;

                        this.fetchPriceSummary(products, quoteDiscounts, customerId.padStart(10, '0'), voucherCode);
                    }
                }
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

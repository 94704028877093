import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, skip, takeUntil, tap } from 'rxjs/operators';
import { AppService, ProductTranslations } from './app.service';
import { ProductsCategoryService } from './products-category.service';
import { MaterialInfo, Quote, QuoteService } from './quote.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { EnvironmentDiffService } from './environment-diff.service';

export interface ProductGroup {
    name: string;
    translationKey: string;
    order?: number;
    products: Array<Product>;
}

export interface Product {
    order?: number;
    material?: string;
    quantity?: number;
    category?: string;
    translation?: ProductTranslations;
    url?: string;
    materialGroup?: string;
    canBeOptional?: boolean;
    optional?: boolean;
}

export interface PriceSummary {
    totalGross: number;
    discount: number;
    totalPrice: number;
    netPrice: number;
    vat: number;
    sum: number;
    voucherValue: number;
}

export interface AssetConversion {
    conversionId: string;
    url: string;
    resolution: string;
    format: string;
}

export interface Asset {
    _id: string;
    type: string;
    name: string;
    assetConversions: AssetConversion[];
}

export interface AssetReference {
    referenceTypeId: string;
    assets: Asset[];
}

export interface PimProduct {
    _id: string;
    name: string;
    type: string;
    assetReferences: AssetReference[];
}

@Injectable({
    providedIn: 'root',
})
export class ProductsService {
    private unsubscribe$: Subject<void> = new Subject<void>();
    constructor(
        private http: HttpClient,
        private quoteService: QuoteService,
        private appService: AppService,
        private productCategory: ProductsCategoryService,
        private environmentDiffService: EnvironmentDiffService
    ) {
        this.subscribeToLanguageChanged$();
        this.materialCache = new Map();
        this.materialRequests = new Map();
        this.productImageCache = new Map();

        this.quoteService
            .getCurrentQuoteObservable()
            .pipe(
                skip(1),
                filter((v) => v === undefined || v.isNew)
            )
            .subscribe(() => {
                this.materialCache.clear();
                this.materialRequests.clear();
            });
    }
    private materialCache: Map<string, MaterialInfo>;
    private materialRequests: Map<string, Observable<string>>;
    private productImageCache: Map<string, string | undefined>;

    private priceSummaryCache: PriceSummary;

    private productCategoriesGroupNamesMap: any = {};

    public getProductImage(material: string) {
        if (this.materialRequests.has(material)) {
            return this.materialRequests.get(material);
        }
        if (this.productImageCache.has(material)) {
            const img = this.productImageCache.get(material);
            return of(img ?? '../../../../assets/images/empty_image.png');
        }
        this.materialRequests.set(material, this.fetchProductImage(material));
        return this.materialRequests.get(material);
    }

    public addPriceSummaryToCache(
        totalGross: number,
        discount: number,
        totalPrice: number,
        netPrice: number,
        vat: number,
        sum: number,
        voucherValue: number
    ) {
        if (!this.priceSummaryCache) {
            this.priceSummaryCache = {
                totalGross: 0,
                discount: 0,
                totalPrice: 0,
                netPrice: 0,
                vat: 0,
                sum: 0,
                voucherValue: 0,
            };
        }
        this.priceSummaryCache.totalGross = totalGross;
        this.priceSummaryCache.discount = discount;
        this.priceSummaryCache.totalPrice = totalPrice;
        this.priceSummaryCache.netPrice = netPrice;
        this.priceSummaryCache.vat = vat;
        this.priceSummaryCache.sum = sum;
        this.priceSummaryCache.voucherValue = voucherValue;
    }

    public clearPriceSummaryCache() {
        this.priceSummaryCache = undefined;
        this.materialCache.clear();
        this.materialRequests.clear();
    }

    public getProducts(
        materials: { material: string; quantity: number; bomItemId?: string }[],
        quote: Quote,
        checkOptional = false
    ): Observable<Product[]> {
        const materialNumbers = materials.map((m) => m.material);
        return forkJoin([
            this.getMaterialInfos(materialNumbers),
            checkOptional ? this.getOptionalMaterials(materialNumbers) : of(<string[]>[]),
        ]).pipe(
            tap(([materialInfos]) =>
                materials
                    .filter(({ bomItemId }) => !!bomItemId)
                    .forEach((material) =>
                        this.quoteService.ensureSubLine(
                            material.bomItemId,
                            material.quantity,
                            materialInfos.find((mi) => mi.name === material.material),
                            quote.quote
                        )
                    )
            ),
            map(([materialInfos, optionals]) =>
                materials.map(({ material, quantity }) => {
                    const response: MaterialInfo = materialInfos.find((mi) => mi.name === material);

                    const filteredCategories = response.properties.filter((prop) => {
                        return prop.key === 'ProductCategory';
                    });
                    const categoryName = filteredCategories[0].value.replace(/"/g, '');

                    // PP-402 getting material group
                    let materialGroup = ''; // empty by default
                    const materialGroupProperty = response.properties.filter((it) => it.key === 'MaterialGroups')[0];
                    if (materialGroupProperty !== undefined) {
                        const materialGroupString = materialGroupProperty.value;
                        const materialGroups = JSON.parse(JSON.parse(materialGroupString));
                        materialGroup = materialGroups[this.appService.getSalesOrg()] || '';
                    }
                    const sourceTranslation = response.longText.serializedTranslations;

                    const translation = sourceTranslation.reduce(
                        (obj, ts) => ({ ...obj, [ts.language]: ts.translation }),
                        {}
                    );

                    const canBeOptional = optionals.length ? optionals.includes(material) : true;
                    const optionalCategories = this.environmentDiffService.getOptionalCategoriesFromCache();
                    const defaultOptional = canBeOptional && optionalCategories.includes(categoryName);

                    return <Product>{
                        material,
                        category: categoryName,
                        materialGroup,
                        translation,
                        quantity,
                        canBeOptional,
                        optional: this.quoteService.isProductOptional(quote.quote, material, defaultOptional),
                    };
                })
            )
        );
    }
    public getMaterialInfos(materials: string[], hasImage = true): Observable<MaterialInfo[]> {
        const cached: MaterialInfo[] = [];
        // collect all cached materials
        materials.forEach((material) => {
            if (this.materialCache.has(material)) {
                cached.push(this.materialCache.get(material));
            } else if (hasImage) {
                this.fetchProductImage(material).subscribe(); // just fetch and cache, no need to care about result here
            }
        });
        if (materials.length === cached.length) {
            return of(cached);
        }
        const url = `${environment.http.configuration}material`;
        // fetch only material infos that are not in cache
        const materialIds = materials.filter((name) => !cached.some((m) => m.name === name));
        return this.http
            .get<MaterialInfo[]>(url, {
                params: {
                    salesAreaName: this.appService.getSalesAreaName(),
                    materialIds,
                },
            })
            .pipe(
                tap((materialInfos) => materialInfos.forEach((mi) => this.materialCache.set(mi.name, mi))),
                map((materialInfos) => [...cached, ...materialInfos]) // combine cached and retrieved data
            );
    }

    public fetchProductImage(material: string): Observable<string | undefined> {
        const url = `${environment.http.pim}products/${this.appService.getLanguageOnly()}/${this.appService
            .getLocation()
            .toLowerCase()}/assets`;
        const conversionId = 'AT_AssetPush_Conversion_URL_PNG_210';

        return this.http
            .post<PimProduct[]>(url, {
                products: [material],
            })
            .pipe(
                catchError(() => of(undefined)),
                map((response: PimProduct[] | undefined) => {
                    return response?.[0]?.assetReferences
                        ?.map((assetRef: AssetReference) =>
                            assetRef.assets?.map((asset: Asset) =>
                                asset.assetConversions?.find(
                                    (conv: AssetConversion) => conv.conversionId === conversionId
                                )
                            )
                        )
                        .flat()
                        .find((conversion) => conversion?.url)?.url;
                }),
                tap((imgUrl) => {
                    this.productImageCache.set(material, imgUrl);
                    this.materialRequests.delete(material);
                }),
                shareReplay()
            );
    }

    public getProductListViewModel(products: Array<Product>, getAsFlatList: boolean = false): Array<any> {
        const productsThatDontBelongToAnyGroup = [];
        const externalProducts = [];
        let productListViewModel = [];

        const groupKeys = Object.keys(this.productCategoriesGroupNamesMap);

        // set up initial productGroupViewModels
        groupKeys.forEach((groupKey) => {
            const groupMapObj = this.productCategoriesGroupNamesMap[groupKey];
            const groupViewModel: ProductGroup = {
                name: groupKey,
                translationKey: groupMapObj['phraseapp-key'],
                order: groupMapObj['order'],
                products: [],
            };

            productListViewModel.push(groupViewModel);
        });

        // populate productGroupViewModels with relevant products
        products.forEach((product: Product) => {
            let productBelongsToGroup = false;

            for (const groupKey of groupKeys) {
                const productGroup = this.productCategoriesGroupNamesMap[groupKey];

                const filteredCategories = productGroup.categories.filter((categoryObj: any) => {
                    return product.category === Object.keys(categoryObj)[0];
                });

                if (filteredCategories.length) {
                    productBelongsToGroup = true;

                    const categoryObj = filteredCategories[0];
                    product.order = categoryObj[Object.keys(categoryObj)[0]];

                    const groupViewModels: Array<ProductGroup> = productListViewModel.filter((item) => {
                        return item.name === groupKey;
                    });

                    groupViewModels[0].products.push(product);
                    break;
                }
            }

            // when product doesn't belong to any category then it's part of the "other" productGroup
            if (!productBelongsToGroup) {
                if (product.category === 'EXTERNAL') {
                    externalProducts.push(product);
                } else {
                    productsThatDontBelongToAnyGroup.push(product);
                }
            }
        });

        // sort groups based on their order - ascending
        productListViewModel = productListViewModel.sort((a: ProductGroup, b: ProductGroup) => {
            return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
        });

        // sort products in the groups - ascending
        productListViewModel.forEach((group: ProductGroup) => {
            group.products = group.products.sort((a, b) => {
                return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
            });
        });

        // create the "other" group and sort products alphabetically
        const otherProductGroup: ProductGroup = {
            name: 'otherGroup',
            translationKey: 'COMMON.CATEGORIES.OTHER_PRODUCT_GROUP',
            products: productsThatDontBelongToAnyGroup.sort((a: Product, b: Product) => {
                const textA = this.chooseTranslation(a.translation).toUpperCase();
                const textB = this.chooseTranslation(b.translation).toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            }),
        };

        // create the "external" group and sort products alphabetically
        const externalProductGroup: ProductGroup = {
            name: 'externalGroup',
            translationKey: 'COMMON.CATEGORIES.EXTERNAL_PRODUCT_GROUP',
            products: externalProducts,
        };

        productListViewModel.push(otherProductGroup);
        productListViewModel.push(externalProductGroup);

        if (!getAsFlatList) {
            return productListViewModel.filter((v) => v.products.length);
        }

        // flatten the viewModel (used in summary view for product-table dataSource)
        const flatList = [];
        productListViewModel.forEach((group) => {
            if (group.products.length) {
                const groupName = { groupNameTranslation: group.translationKey };
                flatList.push(groupName);
                group.products.forEach((prod) => {
                    flatList.push(prod);
                });
            }
        });

        return flatList;
    }

    public chooseTranslation(productTranslations?: ProductTranslations) {
        return this.appService.chooseTranslation(productTranslations);
    }

    public getOptionalMaterials(materials: string[]): Observable<string[]> {
        return this.http
            .post<string[]>(
                `${environment.http.baseUrl}material/optional`,
                {
                    materials,
                },
                {
                    params: {
                        salesOrg: this.appService.getSalesOrg(),
                    },
                }
            )
            .pipe(catchError(() => of([])));
    }

    private subscribeToLanguageChanged$(): void {
        this.appService.salesOrg$.pipe(takeUntil(this.unsubscribe$), filter(Boolean)).subscribe((salesOrg: string) => {
            this.productCategory.fetchProductCategoryGroupNameMap(salesOrg).subscribe((res) => {
                this.productCategoriesGroupNamesMap = res['product-category-group-map'];
            });
        });
    }
}

export enum ConditionType {
    standard = 'ZPPV',
    partner = 'ZVGP',
}

interface MaterialProduct {
    materialNumber: string;
    amount: number;
}

export interface DiscountCategory {
    category: string;
    discount: number;
}

export interface PriceLiteParams {
    salesOrg: string;
    materials?: MaterialProduct[];
    discounts: DiscountCategory[];
    priceAdvantageEnabled?: boolean;
}

interface PricesLite {
    totalGross: number;
    totalAdvantage: number;
    totalPrice: number;
}

export interface Prices extends PricesLite {
    netPrice: number;
    vat: number;
    vatRate: number;
    sum: number;
    voucherValue: number;
    extraBonus: number;
}

interface CalculatePriceResponse<T> {
    prices: T;
    isFull: boolean;
    grossPrices?: { material: string; gross: number }[];
}

export interface CalculatePriceLiteResponse extends CalculatePriceResponse<PricesLite> {
    isFull: false;
}

export interface CalculatePriceFullResponse extends CalculatePriceResponse<Prices> {
    isFull: true;
}

export type PriceSummary = CalculatePriceLiteResponse | CalculatePriceFullResponse;

interface ValidationContext {
    key: string;
    value: string;
}
interface ValidationError {
    context: ValidationContext;
}

export interface PriceSummaryValidationError {
    validationErrors: ValidationError[];
}

// Offer Service stuff
export interface OfferCondition {
    type: string;
    value: number;
    condValue?: number;
}

export interface OfferItem {
    itemNumber: number;
    material: string;
    quantity: number;
    group?: string;
    conditions: OfferCondition[];
    itemCategory?: string;
    grossPrice?: number;
    optional: boolean;
}

export interface OfferParams {
    salesOrg: string;
    distrChan: string;
    division: string;
    customerPurchaseNumber: string;
    documentType: string;
    customerNumber: string;
    submissionNumber: string; // COLLECT_NO
    salesforcePlanningId: string; // SF_PLANNING_ID
    salesforceOpportunityId: string; // SALESFORCE_ID
    salesforceRequestId?: string; // QUOTATION_REQUEST_ID
    currency: string;
    schemaId: string;
    voucherId: string;
    offerType: string;
    quoteAssistInfo: string;
    items: OfferItem[];

    // not used in quote assist
    salesOffice?: string;
}

export interface OfferPrintOptions {
    printType: string;
    printLocale: string;
    textMap: {
        COVER: {
            name: string;
            textItems: {
                textModuleName: string;
                textContent: string;
            }[];
        };
    };
    options: {
        draft: boolean;
        printItemPrices: boolean;
        printDiscounts: boolean;
        printLogo: boolean;
    };
}

export interface OfferPriceCalculationParams {
    language: string;
    offer: OfferParams;
    printOptions: OfferPrintOptions;
}

export interface OfferCreationParams extends OfferParams {
    printOptions: OfferPrintOptions;
    texts: {
        [key: string]: string;
    };
}

export interface SetQuoteAsClosedParams {
    sapDocumentNumber: string;
    customerId: string;
    expirationDate: string;
    prices: Prices;
    voucherCode: string;
}

export interface OfferPriceCalculationResponse {
    offer: {
        totalAmount: number;
        grossTotal: number;
        netTotal: number;
        items: OfferItem[];
    };
    prices: Prices;
}

export interface OfferCreationResponse {
    documentNumber: string;
    expirationDate: string;
    messages: {
        type: string;
        message: string;
    }[];
}

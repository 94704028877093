<div fxLayout="column">
    <vi-quest
        [model]="quest$"
        (check)="check($event)"
        (partChange)="page()"
        (reset)="reset($event)"
        (submit)="submit($event)"
        (leave)="backToSecondary()"
        [part]="preselectPart"
    ></vi-quest>
    <mat-error *ngIf="displaySelectCustomerMsg" class="select-customer-msg align-right">
        {{ 'CONFIGURATION.CUSTOMER_SEARCH.SELECT_CUSTOMER_VALIDATION_MESSAGE' | translate }}
    </mat-error>
    <mat-error *ngIf="displayProvideTitleMsg" class="select-customer-msg align-right">
        {{ 'CONFIGURATION.VALIDATION.PLEASE_PROVIDE_TITLE_MESSAGE' | translate }}
    </mat-error>
    <mat-error
        *ngIf="displayMissingMainComponentMsg"
        class="select-customer-msg"
        [innerHTML]="'CONFIGURATION.VALIDATION.MISSING_MAIN_COMPONENT_MESSAGE' | translate"
    >
    </mat-error>
    <mat-error *ngIf="displayPriceSummaryError" class="select-customer-msg align-right">
        {{ 'CONFIGURATION.SUBMIT.PRICE_CALCULATION_ERROR' | translate }}
    </mat-error>
    <div class="wait-msg" *ngIf="displayWaitMessage">
        {{ 'CONFIGURATION.SUBMIT.PLEASE_WAIT_MESSAGE' | translate }}
    </div>
</div>

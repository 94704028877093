import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ConfigurationResultService } from '../../services/configuration-result.service';
import { Product, ProductsService } from '../../services/products.service';
import { Quote, QuoteService } from '../../services/quote.service';
import { ConfigitConfigurationAndProducts } from '../questionnaire/configit-quest-adapter/configit-types.interface';
import { isEqual } from 'lodash';

const HEAT_GENERATOR = 'Wärmeerzeuger';

const HEAT_PUMP = 'Wärmepumpe';

@Injectable({
    providedIn: 'root',
})
export class ProductListService {
    private materialToCategoryMap = {
        HeatingBoilers: HEAT_GENERATOR,
        HybridNewBoiler: HEAT_GENERATOR,
        HeatPumps: HEAT_PUMP,
        PropaneHeatPumps: HEAT_PUMP,
        BrineHeatPumps: HEAT_PUMP,
        HybridE3HP: HEAT_PUMP,
        ElectricBoiler: 'Elektrokessel',
        BiomassBoiler: 'FBS Kessel',
    };
    public isProductListLoading$ = new BehaviorSubject<boolean>(true);
    public productList$: Observable<Product[]>;

    constructor(
        private quoteService: QuoteService,
        private configurationResultService: ConfigurationResultService,
        private productService: ProductsService
    ) {
        this.productList$ = combineLatest([
            this.configurationResultService.configuration$.pipe(
                distinctUntilChanged((prev, current) => isEqual(prev?.bomItems, current?.bomItems))
            ),
            this.quoteService
                .getCurrentQuoteObservable()
                .pipe(
                    distinctUntilChanged((prev, next) =>
                        isEqual(
                            prev?.quote && this.quoteService.getProductLines(prev.quote),
                            this.quoteService.getProductLines(next.quote)
                        )
                    )
                ),
        ]).pipe(
            tap(() => this.isProductListLoading$.next(true)),
            switchMap(([configuration]) => {
                const quote = this.quoteService.getCurrentQuote();
                if (!configuration || !quote?.quote) {
                    return of(undefined);
                }
                return forkJoin([
                    this.getProductsFromConfiguration(configuration, true, quote),
                    this.getExternalProducts(quote),
                ]);
            }),
            filter(Boolean),
            map(([productsFromQuote, externalProducts]) => [...productsFromQuote, ...externalProducts]),
            tap(() => this.isProductListLoading$.next(false)),
            shareReplay(1)
        );
    }

    public validateMainComponent(
        material: string
    ): Observable<{ hasMainComponent: boolean; mainCategory: string; accessoryProducts: string[] }> {
        return this.configurationResultService.configuration$.pipe(
            take(1),
            concatMap((configuration) => this.getProductsFromConfiguration(configuration)),
            map((products) => {
                const mainCategory = this.materialToCategoryMap[material];
                const accessoryProducts = products.filter((p) => p.category !== mainCategory).map((p) => p.material);
                return {
                    hasMainComponent: !mainCategory || products.some((p) => p.category === mainCategory),
                    mainCategory,
                    accessoryProducts,
                };
            })
        );
    }

    private getExternalProducts(quote: Quote): Observable<Product[]> {
        const lines = this.quoteService.getExternalProductLines(quote.quote);
        const materials = lines.map((line) => ({ material: line.variantCode, quantity: line.quantity }));
        return materials.length
            ? this.productService.getProducts(materials, quote).pipe(
                  map((products) =>
                      products.map((product) => {
                          const line = lines.find((l) => l.variantCode === product.material);
                          return {
                              ...product,
                              translation: line.description.serializedTranslations.reduce(
                                  (obj, ts) => ({ ...obj, [ts.language]: ts.translation }),
                                  {}
                              ),
                              category: 'EXTERNAL',
                          };
                      })
                  )
              )
            : of([]);
    }

    private getProductsFromConfiguration(
        configuration: ConfigitConfigurationAndProducts,
        checkOptional = false,
        quote = this.quoteService.getCurrentQuote()
    ): Observable<Product[]> {
        const products = configuration.bomItems.filter((item) => item.componentQuantity > 0);

        if (!quote?.quote || products.length === 0) {
            return of([]);
        }

        this.quoteService.pruneSubLines(
            products.map((p) => p.material),
            quote.quote
        );

        return this.productService.getProducts(
            products.map((p) => ({
                material: p.material,
                quantity: p.componentQuantity,
                bomItemId: p.itemId,
            })),
            quote,
            checkOptional
        );
    }
}
